import React,{useState} from 'react'
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

import {
    Typography,
    Box,FormControlLabel, Snackbar, Alert, Checkbox
  } from '@mui/material';
import { DateAndTimePicker, RewardDateAndTimePicker } from '../DateAndTimePicker';
import { formatDateToDbSup, formatMomentObjectToDate, formatMomentObjectToTime } from 'src/utils';
import ClaimCard from '../ClaimCard/new';


function PublishingOption(props) {
  const {dropData, nextTabErrors, setNextTabErrors} = props;
  const [openCopiedBar , setOpenCopiedBar] = useState(false)
  const vertical =  'top';
  const horizontal = 'center';

  const [startDateValue, setStartDateValue] = useState(formatMomentObjectToDate(props?.dropData?.startAt));
  const [startTimeValue, setStartTimeValue] = useState(formatMomentObjectToTime(props?.dropData?.startAt));
  const [endDateValue, setEndDateValue] = useState(formatMomentObjectToDate(props?.dropData?.endsIn));
  const [endTimeValue, setEndTimeValue] = useState(formatMomentObjectToTime(props?.dropData?.endsIn));

  const [scheduleLaterDisabled, setScheduleLaterDisabled] = useState((props.dropData.schedule_now || props.dropData.schedule_now === 'true'));
  const [value, setValue] = useState(formatDateToDbSup(props?.dropData?.endsIn));
  
  const handleChange = (newValue) => {
    props.handleChildData('endsIn', newValue)
    setValue(newValue);
    setNextTabErrors({...nextTabErrors,['end_date']:{value: null, error: ""}});
  };

  const handleSchedule = (type, val) => {
    if (type === 'allow_reward_end_date' && val === false) {
      props.handleMultipleChilddata({
        'endsIn': null,
        'allow_reward_end_date': val
      });
      setEndDateValue(dayjs(null));
      setEndTimeValue(moment().startOf('day'));
    } else {

      if (type === 'schedule_now' && val === true) {
        setScheduleLaterDisabled(true);
        setStartDateValue(dayjs(null));
        setStartTimeValue(moment().startOf('day'));
        props.handleChildData('startAt', null);
      } else  if(type === 'schedule_now'){
        setScheduleLaterDisabled(false)
      }
      props.handleChildData(type, val);
    }
    
  }
  // console.log(`reward.published_date ->`, dropData.published_date)
  const handleAddnValueData = (type, val) => {
    setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:""}});
    props.handleChildData(type, val);
  }
  return (
    <>
      <Snackbar
        sx={{zIndex: '7 !important'}}
        anchorOrigin={{ vertical, horizontal }}
        open={openCopiedBar}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
      </Snackbar>
        <Box className="publishing_options" pt={dropData.status == 1 ? 0 : 4}>
          {dropData.status !== 1 &&
            <Box>
              <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
              <Typography variant="h1" textAlign="center" className='title'>Publish your reward</Typography>
              <Typography variant="body1" textAlign="center" sx={{mb: 3}} className='subtitle'>And set when the reward starts and ends</Typography>
            </Box>
          }


          {/* claim card preview */}
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3}} className='claim-modal'>
            <ClaimCard reward={dropData} title={props.title} description={props.description} publishedDt={props.dropData.schedule_now ? new Date() : dropData.startAt} />
          </Box>

          <Box width={dropData.status == 1 ? "100%" : "712px"} mx="auto">
            <Box pb="20px">
              {/* schedule area */}
              <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold", fontSize: "16px", m: '20px 0 10px 10px'}}>Schedule</Typography>

              <FormControlLabel
                sx={{ m: '0 !important', alignItems: 'start', width: '100%', '& .MuiTypography-body1': {width: '100%'} }}
                
                control={
                  <Checkbox
                    disableRipple
                    icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                    checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                    checked={!props.dropData.schedule_now}
                    onChange={(e) => {
                      handleSchedule('schedule_now', !e.target.checked)
                    }}
                    sx={{pt:'0.2rem'}}
                  />
                }
                label={
                  
                  <Box>
                    <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                      <Box component="span">Specify a release date</Box>
                    </Typography>
                    {
                      !props.dropData.schedule_now &&
                      <Box sx={{mt: '10px'}}>
                        <Box>
                          <DateAndTimePicker formtype="new" dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type="startAt" handleChildData={props.handleChildData} startDateValue={startDateValue} setStartDateValue={setStartDateValue} startTimeValue={startTimeValue} setStartTimeValue={setStartTimeValue} timeWidth="7rem" dateWidth="49.25rem" is_scheduled={props.dropData.schedule_now} />
                        </Box>
                        <FormControlLabel  sx={{"& .MuiFormControlLabel-label": {opacity: props.dropData.is_coming_soon ? '1' : '0.5'}, "& .MuiCheckbox-root": {pl:'0'}, ml: "0", mt: "5px"}} onChange={(e) => {handleAddnValueData('is_coming_soon', e.target.checked)}} checked={props.dropData.is_coming_soon} control={<Checkbox defaultChecked disableRipple icon={<img src="/assets/img/Uncheck.svg" width="18" alt="" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="18" alt="" />}  />} label={`Display as "coming soon"`} />
                      </Box>
                    }
                  </Box>
                }
              />

              <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold", fontSize: "16px", m:'20px 0 10px 10px'}}>End Date</Typography>

              <FormControlLabel
                sx={{ m: '0 !important', alignItems: 'start', width: '100%', '& .MuiTypography-body1': {width: '100%'} }}
                control={
                  <Checkbox
                    disableRipple
                    icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                    checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                    checked={props.dropData.allow_reward_end_date}
                    onChange={(e) => {
                      handleSchedule('allow_reward_end_date', e.target.checked)
                      // handleChange("")
                    }}
                    sx={{pt:'0.2rem'}}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                      <Box component="span">Specify an end date</Box>
                    </Typography>
                  </Box>
                }
              />

              {
                props.dropData.allow_reward_end_date &&
                <Box sx={{mt: '5px', ml: '10px'}}>
                  <RewardDateAndTimePicker requiredf={true} formtype="new" dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type={["end_date", "end_time"]} suffix_name="End" handleChildData={handleChange} startDateValue={endDateValue} setStartDateValue={setEndDateValue} startTimeValue={endTimeValue} setStartTimeValue={setEndTimeValue} timeWidth="16.25rem" dateWidth="43rem" />
                </Box>
              }
            </Box>
          </Box>
        </Box>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishingOption)
