import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import { connect, useSelector } from "react-redux";
import { allowedCustomWallet } from "src/utils";
import { shortEmailAddress, shortenPhoneNumber, shortEthAddress } from "src/utils/ethereum";
import { NavLink } from "react-router-dom";
import { logoutUser } from "src/services/actions";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    backgroundColor: "#f3f3f3",
    // width: "13%",
    borderRadius: "0px 0px 8px 8px",
    position: "absolute",
    left: 20,
    top: "100%",
    minWidth: "15vw",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function UserBoxModal(props) {
  const {user} = useSelector((state) => state)
  const [openModals, setOpenModals] = React.useState(true);

  const handleOpenModals = (prevCheck) => {
    setOpenModals((prevCheck) => !prevCheck);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    props.logoutUser();
  };

  return (
    <>
      <Box component="div">
        <Box
          sx={{
            background: "#f3f3f3",
            // background: "red",
            borderRadius: "5px",
            width: "219px",
            padding: "15px 0px",
          }}
        >
          <Button
            onClick={handleOpenModals}
            sx={{
              backgroundColor: "#f3f3f3",
              color: "black",
              display: "flex",
              alignItems: "center",
              // borderRadius: "8px",
              height:"44px",
              "&:hover": {
                color: "#000",
                backgroundColor: "#F3F3F3",
                cursor: "pointer",
              },
            }}
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            endIcon={openModals ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }
          >
            <ImageList
              variant="masonry"
              sx={{ margin: "0px", marginRight: "10px" }}
              style={{ columnCount: "0px", columnGap: "0px" }}
            >
              <ImageListItem sx={{ width: "44px", height: "44px" }}>
                <img
                  src="/assets/images/profile_img.png"
                  alt="Profile "
                  loading="lazy"
                />
              </ImageListItem>
            </ImageList>

            <Box sx={{ textAlign: "left", fontFamily:"General Sans", fontWeight:"400" }}>
              {user.data.fname &&
              <>
              <Typography
                variant="strong"
                className="font-gs-b"
                sx={{
                  fontSize: "14px",
                }}
              >
                {user.data.fname}
              </Typography>
              <br/>
              </>
            }
              <Typography variant="span" sx={{fontFamily:"Roboto", fontWeight:400, fontSize: "14px",}}>
              {allowedCustomWallet(user.data?.wallet_type) ? shortEmailAddress(user.data.email) : user.data?.wallet_type === 'mobile' ? shortenPhoneNumber(user.data.phone) : shortEthAddress(user.data.eth_address)}
              </Typography>
            </Box>
          </Button>
          {openModals && (
            <Box sx={{ width: "179px", padding: "15px 20px 0px" }}>
              <MenuItem
                className="font-gs-sb"
                sx={{
                  fontSize: "1rem",
                  padding: "0px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #dddddd",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#F3F3F3",
                    background: "#f3f3f3",
                  },
                  borderRadius: "0px"
                }}
                to={`/profile`}
                component={NavLink}
                disableRipple
              >
                Profile
              </MenuItem>
              <MenuItem
                className="font-gs-sb"
                sx={{
                  fontSize: "1rem",
                  padding: "10px 0px",
                  borderBottom: "1px solid #dddddd",
                  backgroundColor: "#F3F3F3",
                  width: "100%",

                  "&:hover": {
                    backgroundColor: "#F3F3F3",
                    background: "#f3f3f3",
                  },
                }}
                onClick={() => {
                  window.open('https://help.step3.io')
                }}
                disableRipple
              >
                Support and FAQ
              </MenuItem>
              <MenuItem
                className="font-gs-sb"
                sx={{
                  fontSize: "1rem",
                  width: "100%",
                  padding: "0px",
                  paddingTop: "10px",
                  "&:hover": {
                    backgroundColor: "#F3F3F3",
                    background: "#f3f3f3",
                  },
                }}
                onClick={handleLogout}
                disableRipple
              >
                Log out
              </MenuItem>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBoxModal);
